<template>
  <div class="contenedor-card" v-if="cantidadServicios > 0">
    <div class="card-carrito" v-for="item in cart" :key="item.id">
      <div class="nombre-estudio">
        <span>{{ item.name }}</span
        ><span><i class="icon-eliminar" @click="eliminarServicio(item)"></i></span>
      </div>
      <span class="lblCantidad-item">Cantidad {{ item.cantidad }}</span>
      <div class="totales">
        <div class="cantidad">
          <div class="contenedor-icon" @click="restar(item)">
            <span class="icon-menos"> - </span>
          </div>
          <span class="lbl-sumaresta">{{ item.cantidad }}</span>
          <div class="contenedor-icon" @click="sumar(item)">
            <i class="icon-mascarrito"></i>
          </div>
        </div>
        <!-- <span class="lbl-sumacarrito">${{ item.precio * item.cantidad }}</span> -->
        <span class="lbl-sumacarrito">
          <span class="moneda">$</span>
          <span class="cantidad-moneda">{{ getTotalEntero(item) }}</span>
          <span class="decimales-moneda">.{{getTotalDecimal(item)}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useCarritoStore } from "@store/carritoStore";
import monto from '@mixins/monto.js'

export default {
  name: "contenedor-cards",
  props: {},
  data() {
    return {
      cartStore: useCarritoStore()
    };
  },
  mounted() {
  },
  computed: {
    cart() {
      return this.cartStore.cartItems;
    },
    cantidadServicios() {
      return this.cartStore.totalItems;
    },
  },
  methods: {
    restar(item) {
      this.cartStore.restarCantidad(item.id);
    },
    sumar(item) {
      this.cartStore.sumarCantidad(item.id);
    },
    eliminarServicio(item) {
      this.cartStore.removeItem(item.id);
    },
    // getTotal(item) {
    //   let total = +item.precio * +item.cantidad
    //   return monto.splitNumber(total).formatted
    // },
    getTotalEntero(item){
      let total = +item.price * +item.cantidad
      return monto.splitNumber(total).integer
    },
    getTotalDecimal(item) {
      let total = +item.price * +item.cantidad
      return monto.splitNumber(total).decimal
    },
  },
};
</script>
<style scoped>
.card-carrito {
  height: 102px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 11px 16px 16px;
  margin-bottom: 12px;
}
.totales {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nombre-estudio {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #242424;
  opacity: 1;
}
.icon-eliminar {
  color: #1035d3;
  font-size: 19px;
}
.lblCantidad-item {
  font: normal normal 500 12px/16px Roboto;
  letter-spacing: 0px;
  color: #898989;
  opacity: 1;
  margin-bottom: 8px;
}
.cantidad {
  display: flex;
  align-items: center;
}
.icon-menos {
  font-size: 27px;
}
.icon-mascarrito {
  font-size: 10px;
}
.contenedor-icon {
  width: 30px;
  height: 30px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lbl-sumaresta {
  min-width: 50px;
  text-align: center;
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  color: #13328A;
  opacity: 1;
}
.lbl-sumacarrito {
  font: normal normal bold 26px / 26px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  display: flex;
  align-items: baseline;
}
.moneda,
.decimales-moneda {
  font: normal normal bold 20px/26px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.cantidad-moneda {
  font: normal normal bold 26px/26px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
}
</style>