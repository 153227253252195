import api from '@services/api';
const service = {};
const URL = '/patients';


service.getPacienteByTelefono = async (number) => {
  try {
    const paciente = await api.get(`${URL}/phone/${number}`);
    return { error: false, paciente: paciente.data };
  } catch (error) {
    console.log(error)
    return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
  }
};

export default service;