import api from '@services/api';
import { useUserStore } from '@store/userStore.js';
const service = {};
const URL = '/auth';


service.login = async (correo, password) => {
  try {
    const session = await api.post(`${URL}/authenticate`, { correo: correo.trim(), password }).then((res) => res.data);
    if (session.error == true) {
      if (session.message) {
        return { error: true, msj: session.message };
      } else {
        return { error: true, msj: 'No se pudo iniciar sesión, intentelo de nuevo' };
      }
    } else {
      useUserStore().setSession({session,user:correo});
      return { error: false, session: session.usuario };
    }
  } catch (error) {
    console.log(error)
    return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error al iniciar sesión, intentelo de nuevo.' };
  }
};
service.logOut = async () => {
  try {
    console.log('log')
    useUserStore().logout()
    // this.$router.push("/")
  } catch (error) {
    useUserStore().logout()
    // this.$router.push("/")
  }
}

service.refresh = async () => {
  const refreshToken = sessionStorage.getItem('refreshToken');
  const token = sessionStorage.getItem('token');
  return await api.post(`${URL}/refresh`, { refreshToken, token });
};
export default service;
