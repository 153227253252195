import api from '@services/api';
const service = {};
const URL = '/services';


service.getBusquedaServicios = async (buscar) => {
  try {
    const busqueda = await api.get(`${URL}/?keyword=${buscar}&status=1`);
    console.log('get', busqueda)
    return { error: false, busqueda: busqueda.data };
  } catch (error) {
    console.log(error)
    return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
  }
};

export default service;