<template>
  <div class="container-control" :class="getClases">
    <label v-if="flagLabel">{{ label }}</label>
    <input
      :type="type"
      v-model="value"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="isDisabled"
      :autofocus="flagAutoFocus"
      :autocomplete="autocomplete"
      :inputmode="inputmode"
      ref="focus"
      @blur="
        ($event) => {
          $emit('blur', $event);
        }
      "
      :style="combinedStyles"
      :autocapitalize="autocapitalize"
      :class="{ uppercase: flagMayusculas }"
    />
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Input',
  props: {
    modelValue: {},
    autocapitalize: {
      type: String,
      default: 'none',
    },
    type: {
      type: String,
      default: 'text',
    },
    inputmode: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    flagSombra: {
      type: Boolean,
      default: false,
    },
    fondoGris: {
      type: Boolean,
      default: false,
    },
    centrado: {
      type: Boolean,
      default: false,
    },
    textTransform: {
      type: String,
      default: '',
    },
    flagMayusculas: {
      type: Boolean,
      default: false,
    },
    flagLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    flagBorder: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    labelMod: {
      type: Boolean,
      default: false,
    },
    flagAutoFocus: {
      type: Boolean,
      default: false,
    },
    height50: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '45px'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    getClases() {
      return {
        sombra: this.flagSombra,
        readyOnly: this.fondoGris,
        center: this.centrado,
        flagBorder: this.flagBorder,
        'label-mod': this.labelMod,
        height50: this.height50,
        desactivado: this.readonly,
      };
    },
    getInputStyle() {
      return {
        'text-transform': this.textTransform,
      };
    },
    combinedStyles() {
      return {
        ...this.getInputStyle,
        height: this.computedHeight,
        width: this.computedWidth
      };
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    computedHeight() {
      return this.height;
    },
    computedWidth() {
      return this.width;
    }
  },
  methods: {
    focus() {
      this.$refs.focus.focus();
    }
  }
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}
.desactivado input {
  background: #f0f0f0 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  -webkit-box-shadow: 0px 3px 6px #00000029 !important;
  -moz-box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #cecece !important;
}
.flagBorder input {
  border-radius: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #404040;
}
label {
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  width: 50px;
  margin-left: 30px;
  background: white;
  position: relative;
  z-index: 2;
  top: 11px;
}
.label-mod label {
  position: absolute;
  top: -11px;
  width: unset;
  padding: 0px 10px;
  text-align: center;
}
.container-control {
  position: relative;
}
input {
  border: 0px;
  outline: none;
  width: 100%;
  border-radius: 4px;
  padding: 0px 15px;
  color: #707070;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.9;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;

}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sombra input {
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  -webkit-appearance: none;
  appearance: none;
}
.height50 input {
  height: 50px !important;
}
.readyOnly input {
  background-color: #f4f4f4;
}
.center input {
  text-align: center;
}
.textTransform {
  text-transform: uppercase;
}
input::placeholder {
  color: #707070ed;
  opacity: 0.5;
}
.fondo-gris {
  background-color: #f0f0f0;
}
</style>
