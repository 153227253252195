<template>
  <div class="skeleton" >
    <div class="skeleton-animation skeleton-preload" :style="style">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonVue',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: '60px'
    },
    width: {
      type: String,
      default: '100%'
    },
  },
  computed: {
    style () {
      return {
        height: this.height,
        width: this.width
      }
    }
  }
}
</script>

<style>
.skeleton-animation {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}
.skeleton-preload::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.9),
    transparent
  );
}
.skeleton-preload::after {
  -webkit-animation: loading-ske 1.5s infinite;
  animation: loading-ske 1.5s infinite;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: loading-ske;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}
@-webkit-keyframes loading-ske {
  100% {
    transform: translateX(100%);
  }
}

@keyframes loading-ske {
  100% {
    transform: translateX(100%);
  }
}
</style>