<template>
  <label 
    :for="id"
    :style="style"
  >
    <input 
      :id="id"
      type="radio" 
      v-model="value"
      :name="nameEntrada"
      :value="valueEntrada"
      
    />
    <p>{{label}}</p>
  </label>
  
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    id: {
      type: String,
      default: ''
    },
    modelValue: {},
    valueEntrada: {
      type: String,
      default: ''
    },
    nameEntrada: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '50px'
    },
    marginRight: {
      type: String,
      default: '0px'
    },
    label: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    style () {
      let width = this.width == '' ? '' : 'width: ' + this.width + ';'
      let mR = this.marginRight == '' ? '' : 'margin-right: ' + this.marginRight + ';'

      return `${width}${mR}`
    }
  }
}
</script>

<style scoped>
input {
  opacity: 0;
  width: 0px;
  height: 0px;
  pointer-events: none;
}
label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
}
label p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  background: #68CBEB29 0% 0% no-repeat padding-box;
  border: 1px solid #70707043;
  border-radius: 4px;
  min-height: 40px;
  width: 100%;
  transition: 0.3s all;
  font-style: normal;
  font-variant: normal;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
}
label input:checked ~ p {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  opacity: 1;
  transition: 0.3s all;
}

label.tipo-secundario p {
  border: 1px solid #4B566C;
  color: #4B566C;
}
label.tipo-secundario input:checked ~ p {
  border-color: #008ED3;
  background-color: #E3F6FF;
  color: #008ED4;
}
</style>