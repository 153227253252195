<template>
  <main class="content">
    <div class="modal-overlay"></div>
  </main>
  <div class="contenedor-footer">
    <footer>
      <div class="contenedor-cerrar" @click="closeModal">
        <i class="icon-cerrar"></i><span class="copie-cerrar">Cerrar</span>
      </div>
      <p class="lbl-compartir">Compartir</p>
      <div class="contenedor-compartir">
        <!-- <div class="item"  > -->
        <!-- <div class="item" :class="useUser.datosPaciente?.telefono ? '' : 'deshabilitado'" @click="itemSeleccionado(0)"> -->
        <div class="item" @click="itemSeleccionado(0)">
          <div class="item-circulo" :class="indexSeleccionado === 0 ? 'active' : ''">
            <img src="@assets/icons/icon-whats.png" alt="Icono 1" />
          </div>
          <p>Whatsapp</p>
        </div>
        <div class="item" @click="itemSeleccionado(5)">
          <div class="item-circulo" :class="indexSeleccionado === 5 ? 'active' : ''">
            <i class="icon-shareqr"></i>
          </div>
          <p>QR</p>
        </div>
        <div class="item" @click="itemSeleccionado(2)">
          <div class="item-circulo" :class="indexSeleccionado === 2 ? 'active' : ''">
            <i class="icon-sharesms"></i>
          </div>
          <p>SMS</p>
        </div>
        <div class="item" @click="itemSeleccionado(3)">
          <div class="item-circulo" :class="indexSeleccionado === 3 ? 'active' : ''">
            <i class="icon-sharecorreo"></i>
          </div>
          <p>Correo</p>
        </div>
        <div class="item" @click="itemSeleccionado(4)">
          <div class="item-circulo" :class="indexSeleccionado === 4 ? 'active' : ''">
            <i class="icon-shareimprimir"></i>
          </div>
          <p>Imprimir</p>
        </div>
        <div class="item" :class="permisos ? '' : 'deshabilitado'" @click="itemSeleccionado(1)">
          <div class="item-circulo" :class="indexSeleccionado === 1 ? 'active' : ''">
            <i class="icon-sharelink"></i>
          </div>
          <p>Enlace</p>
        </div>
      </div>
      <div class="contenedor-botones">
        <!-- <Button id="btnRegresar" class="btn btn-regresar" @click="regresar"
          >Regresar</Button
        >
        <Button id="btnContinuar" class="btn btn-finalizar" @click="finalizar"
          >Finalizar</Button
        > -->
        <Button id="btnCompartir" class="btn-compartir" @click="compartir">Compartir</Button>
      </div>
    </footer>
  </div>
</template>
<script>
import { useCarritoStore } from "@store/carritoStore";
import Button from "@components/General/Form/Button.vue";
import { useUserStore } from "@store/userStore.js";

export default {
  name: "FooterCompartir",
  components: {
    Button,
  },
  emits: ["cerrar", "finalizar", "qr", "accion"],
  props: {
    permisos: {
      type: Boolean,
      default: false
    }
  },
  mixins: [],
  data() {
    return {
      cartStore: useCarritoStore(),
      useUser: useUserStore(),
      datos: null,
      indexSeleccionado: null,
    };
  },
  beforeMount() {
    this.datos = this.useUser.datosPaciente
  },
  computed: {
    cantidad() {
      return this.cartStore.totalItems;
    },
  },
  methods: {
    closeModal() {
      this.$emit("cerrar");
    },
    regresar() {
      this.$emit("cerrar");
    },
    finalizar() {
      this.$emit("finalizar");
    },
    qr() {
      this.$emit("qr");
    },
    // getClass(index) {
    //   return {
    //     'deshabilitado': this.itemsStatus[index].disabled, // Aplica la clase 'disabled' si el item está deshabilitado
    //     'active': index === this.indexSeleccionado // Aplica la clase 'active' si es el item seleccionado
    //   };
    // },
    // getActive(index) {
    //   return {
    //     'active': index === this.indexSeleccionado
    //   }
    // },
    itemSeleccionado(index) {
      this.indexSeleccionado = index;
      this.$emit("accion", this.indexSeleccionado);
    },
    compartir() {
      // if(this.indexSeleccionado == 5) {
        // this.$emit("accion", this.indexSeleccionado);
        this.$emit('finalizar')
      // }
    }
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.contenedor-footer {
  width: 100%;
  display: flex;
  margin: auto;
  transform: translateX(0px);
  position: fixed;
  bottom: 0;
  z-index: 1003;
  transition: transform 0.3s ease;
}
footer {
  transition: transform 0.3s ease;
  padding: 10px 20px;
  height: 410px;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  opacity: 1;
  position: relative;
  color: #fff;
  text-align: center;
  width: 100%;
  display: flex;
  border-radius: 15px;
  clip-path: polygon(
    0 100%,
    0 0,
    35% 0%,
    40% 23px,
    60% 23px,
    65% 0%,
    100% 0,
    100% 100%,
    0% 100%
  );
}

.modal-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  overflow: hidden;
}
.contenedor-cerrar {
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 7px 15px;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.lbl-compartir {
  font: normal normal bold 18px/24px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: left;
}
.contenedor-compartir {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-items: center;
  margin: 20px 0px;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  cursor: pointer;
}
.item > .item-circulo {
  width: 66px;
  height: 66px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  align-content: center;
}
.item img {
  width: 100%;
  height: auto;
}
.item i {
  color: #1035d3;
  font-size: 29px;
}
.item p {
  font: normal normal bold 12px/16px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 6px;
}
.item-circulo.active {
  background-color: #88cce2;
}
.icon-sharecorreo {
  margin-left: -18px;
  font-size: 19px !important;
}
.contenedor-botones {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  margin-top: 32px;
}
.deshabilitado {
  opacity: .20;
  pointer-events: none
}
.btn {
  width: 160px;
  height: 45px;
  border: 0;
}
.btn-regresar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.btn-finalizar {
  background: #1035d3 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.btn-compartir {
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  cursor: pointer;
}
.btn-compartir:hover {
  background:  #d5d5d5 0% 0% no-repeat padding-box;
}
@media (min-width: 767px) {
  .contenedor-footer,
  .modal-overlay,
  .content {
    /* margin: auto; */
    width: 60%;
  }
}
@media (min-width: 1279px) {
  .contenedor-footer,
  .modal-overlay,
  .content {
    /* margin: auto; */
    width: 40%;
  }
}
</style>