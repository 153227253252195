<template>
  <ModalBase class="" :mostrarHeader="true" @cerrar="closeModal">
    <div class="contenido-modal">
      <img src="@assets/icons/icon-orden_exito.svg" alt="" />
      <p>La orden médica ha sido compartida con éxito.</p>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from "@components/General/Modal/ModalBase.vue";

export default {
  name: "ModalExito",
  components: {
    ModalBase,
  },
  props: {},
  data() {
    return {};
  },
  emits: ['cerrar'],
  beforeMount() {},
  methods: {
    closeModal() {
      this.$emit("cerrar");
    },
  },
  watch: {},
};
</script>

<style scoped>
.contenido-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50%;
  padding: 40px;
}
.contenido-modal > img {
  width: 153px;
  height: 158px;
}
.contenido-modal > p {
  font: normal normal bold 22px/29px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  text-align: center;
  margin-top: 50px;
}
</style>
