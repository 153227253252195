<template>
  <div class="app-container">
    <Header :showBuscador="true" @busqueda="busquedaNombre"></Header>
    <DatosPaciente v-if="datosPaciente" :datos="datosPaciente" @editar="editar"></DatosPaciente>
    <main class="content" v-show="!showModalCarrito">
      <div class="categorias-container">
        <Categorias :categories="categories" :load="loadCategorias" @categoriaSeleccionada="getProductos"></Categorias>
      </div>
      <div class="estudio-container">
        <Estudios :estudios="estudios" :load="loadServicios" @agregar="estudioAgregado"></Estudios>
      </div>
    </main>
    <FooterCarrito
      v-show="!showModalCarrito"
      v-if="opcionRealizar == 1"
      @abrirCarrito="abrirCarrito"
    ></FooterCarrito>
  </div>
  <ModalCarrito
    v-if="showModalCarrito"
    @cerrar="cerrarCarrito"
    :nuevoServicio="nuevoServicio"
    @update:nuevoServicio="updateNuevoServicio"
  ></ModalCarrito>
  <AgregarPaciente v-if="showAgregar" @cerrar="cerrarAgregar" @regresar="cerrarAgregar" @agregado="agregado" :editar="true"> </AgregarPaciente>
</template>
<script>
import Header from "@components/Layouts/Header.vue";
import Categorias from "@components/Servicios/Categorias.vue";
import Estudios from "@components/Servicios/ContenedorCards.vue";
import FooterCarrito from "@components/Layouts/FooterCarrito.vue";
import ModalCarrito from "@components/Modal/Carrito.vue";
import CategoriasServices from "@services/categorias.js";
import ServicosServices from "@services/servicios.js";
import { useCarritoStore } from "@store/carritoStore";
import { useUserStore } from "@store/userStore.js";
import DatosPaciente from '@components/Layouts/DatosPaciente.vue';
import AgregarPaciente from '@components/Modal/AgregarPaciente.vue';

export default {
  name: "ServiciosView",
  components: {
    Header,
    Categorias,
    Estudios,
    FooterCarrito,
    ModalCarrito,
    DatosPaciente,
    AgregarPaciente,
  },
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {
      cartStore: useCarritoStore(),
      useUser: useUserStore(),
      showAgregar: false,
      nuevoServicio: false,
      carrito: [],
      showModalCarrito: false,
      loadCategorias: true,
      categories: [],
        // { id: 1, name: "Análisis de Sangre", icon: "icon-analisis.svg" },
        // { id: 15, name: "Imagenología", icon: "icon-imagenologia.svg" },
        // { id: 3, name: "Ultrasonido", icon: "icon-ultrasonido.svg" },
        // { id: 40, name: "Estudios Clínicos", icon: "icon-estudios.svg" },
        // { id: 25, name: "Resonancia Magnética", icon: "icon-resonancia.svg" },
        // { id: 16, name: "Análisis de Sangre 2", icon: "icon-analisis.svg" },
        // { id: 7, name: "Imagenología 2", icon: "icon-imagenologia.svg" },
        // { id: 18, name: "Ultrasonido 2", icon: "icon-ultrasonido.svg" },
        // { id: 92, name: "Estudios Clínicos 2", icon: "icon-estudios.svg" },
        // { id: 10, name: "Resonancia Magnética 2", icon: "icon-resonancia.svg" },
      // ],
      loadServicios: true,
      idCategoriaBusqueda: null,
      estudios: [
        // {
        //   id: 1,
        //   name: "Análisis de Sangre",
        //   precio: "1200.99",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 12,
        //   name: "Imagenología",
        //   precio: "1200.50",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 3,
        //   name: "Ultrasonido",
        //   precio: "699.99",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 45,
        //   name: "Estudios Clínicos",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 15,
        //   name: "Resonancia Magnética",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 6,
        //   name: "Análisis de Sangre 2",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 7,
        //   name: "Imagenología 2",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 80,
        //   name: "Ultrasonido 2",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 19,
        //   name: "Estudios Clínicos 2",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
        // {
        //   id: 100,
        //   name: "Resonancia Magnética 2",
        //   precio: "1200",
        //   descripcion:
        //     "Esta es la descripcion que tiene cada estudio en la parte en la que se expande",
        // },
      ],
    };
  },
  beforeMount() {
    this.useUser.guardarRuta('Servicios')
    this.cartStore.iniciarCarrito();
    this.getCategorias();
  },
  computed: {
    opcionRealizar() {
      return this.useUser.realizar;
    },
    datosPaciente() {
      return this.useUser.datosPaciente
    }
  },
  methods: {
    async getCategorias() {
      this.loadCategorias = true
      let cat = await CategoriasServices.getCategorias();
      if(!cat.error) {
        // setTimeout(() => {
          this.categories = cat.categorias
          this.loadCategorias = false
        // }, 3000)
      } else {
        this.categories = []
        // setTimeout(() => {
          this.loadCategorias = false
        // }, 3000)
      }
    },
    async getProductos(id) {
      this.idCategoriaBusqueda = id
      // if(id == '' || id == null || id == undefined) {
      //   id = this.categories[0].id
      // }
      this.loadServicios = true
      let productos = await CategoriasServices.getServiciosById(id);
      if(!productos.error) {
        // setTimeout(() => {
          this.estudios = productos.servicios.data[0].services
          this.loadServicios = false
        // }, 3000)
      } else {
        // setTimeout(() => {
          this.estudios = []
          this.loadServicios = false
        // }, 3000)
      }
    },
    async busquedaNombre(buscar) {
      if(buscar == '') {
        this.getProductos(this.idCategoriaBusqueda)
      } else {
        let busqueda = await ServicosServices.getBusquedaServicios(buscar);
        this.estudios = busqueda.busqueda
      }
    },
    abrirCarrito() {
      this.showModalCarrito = true;
    },
    cerrarCarrito() {
      this.showModalCarrito = false;
    },
    estudioAgregado() {
      this.nuevoServicio = true; 
      this.showModalCarrito = true;
    },
    updateNuevoServicio() {
      this.nuevoServicio = false;
    },
    editar() {
      this.showAgregar = true;
    },
    cerrarAgregar() {
      this.showAgregar = false
    },
    agregado() {
      this.showAgregar = false 
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.showModalCarrito && to.path !== '/resumen') {
      this.showModalCarrito = false; // Cerrar el modal
      next(false); // Evitar la navegación
    } else {
      next();
    }
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.container {
  width: 100% !important;
}
.servicios {
  margin-top: 75px;
  width: 100%;
  padding: 0 25px;
}
.categorias-container {
  width: 100%;
}
.estudio-container {
  margin-top: 20px;
  max-height: calc(100vh - 300px);
  overflow: scroll;
  margin-bottom: 102px;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
.module-container {
  margin-top: 65px !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}
</style>
<style>
@media (min-width: 1279px) {
  .content {
    width: 100%;
  }
}
</style>