<template>
  <div class="contenedor-footer">
    <footer :style="descuentos ? 'height: 316px;' : 'height: 184px;'">
      <div class="subtotales" v-if="descuentos">
        <div class="subtotal-item">
          <span>Total de tu carrito</span><span>$100.00</span>
        </div>
        <div class="subtotal-item">
          <span>Descuentos</span><span>$100.00</span>
        </div>
        <div class="subtotal-item">
          <span>Tienes un código de descuento?</span
          ><span class="i-descuento"><i class="icon-addpromo"></i></span>
        </div>
      </div>
      <div class="contenedor-total">
        <span class="lbl-total">Total a pagar</span
        ><span class="lbl-monto"
          ><span class="moneda">$</span>
          <span class="cantidad">{{ getTotalEntero }}</span>
          <span class="decimales">.{{ getTotalDecimal }}</span></span
        >
      </div>
      <div class="contenedor-botones">
        <Button
          id="btnRegresar"
          class="btn"
          v-bind:class="getTotalItems > 0 ? 'btn-agregar' : 'btn-agregar-vacio'"
          @click="regresar"
          >{{ getTotalItems > 0 ? "Continuar agregando" : "Agregar" }}</Button
        >
        <Button
          v-if="getTotalItems > 0"
          id="btnContinuar"
          class="btn btn-finalizar"
          @click="finalizar"
          >Finalizar</Button
        >
      </div>
    </footer>
  </div>
</template>
<script>
import { useCarritoStore } from "@store/carritoStore";
import Button from "@components/General/Form/Button.vue";
import monto from '@mixins/monto.js'

export default {
  name: "FooterModalResumen",
  components: {
    Button,
  },
  emits: ["regresar", "finalizar"],
  props: {},
  mixins: [],
  data() {
    return {
      cartStore: useCarritoStore(),
      descuentos: false,
    };
  },
  beforeMount() {},
  computed: {
    getTotalItems() {
      return this.cartStore.totalItems;
    },
    getTotal() {
      return this.cartStore.totalPrecio;
    },
    getTotalEntero(){
      return monto.splitNumber(this.cartStore.totalPrecio).integer
    },
    getTotalDecimal() {
      return monto.splitNumber(this.cartStore.totalPrecio).decimal
    },
  },
  methods: {
    regresar() {
      this.$emit("regresar");
    },
    finalizar() {
      this.$emit("finalizar");
    },
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.contenedor-footer {
  width: 100%;
  display: flex;
  margin: auto;
  transform: translateX(0px);
  position: fixed;
  bottom: 0;
}
footer {
  position: relative;
  color: #fff;
  text-align: center;
  width: 100%;
  display: flex;
  /* UI Properties */
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 10px 10px;
}
.subtotales {
  display: flex;
  flex-direction: column;
  padding: 16px 10px 16px 16px;
  width: 100%;
}
.subtotal-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #d3d3d3;
}
.i-descuento {
  width: 30px;
  height: 30px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-addpromo {
  color: #1035d3;
  font-size: 11px;
}
.contenedor-total {
  height: 57px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-top: 20px;
}
.lbl-total {
  text-align: left;
  font: normal normal bold 22px/29px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
}
.lbl-monto {
  text-align: left;
  font: normal normal bold 22px/26px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
}
.contenedor-botones {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 10px 0px;
  gap: 10px;
}
.btn {
  height: 45px;
  border: 0;
}
.btn-agregar {
  background: #88cce2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal 500 16px/21px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  width: 173px;
  flex-shrink: 0;
}
.btn-finalizar,
.btn-agregar-vacio {
  width: 160px;
  height: 45px;
  background: #1035d3 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal 500 16px/21px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.btn-agregar-vacio {
  width: 100%;
  height: 45px;
}
.moneda,
.decimales {
  font: normal normal bold 20px/26px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.cantidad {
  font: normal normal bold 26px/26px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
}
@media (min-width: 767px) {
  .contenedor-footer {
    width: 60%;
  }
}
@media (min-width: 1279px) {
  .contenedor-footer {
    width: 40%;
  }
}
</style>