<template>
  <div class="app-container">
    <main class="content">
      <div class="home">
        <span class="copiePregunta">¿Cómo desea realizarla?</span>
        <div class="opcion-realizar" @click="opcion(1)">
          <img
            class="icon-opcion"
            src="@assets/icons/icon-addPaciente.svg"
            alt=""
          />
          <span class="copie-opcion">Agregar paciente</span>
        </div>
        <div class="opcion-realizar" @click="opcion(2)">
          <img
            class="icon-opcion-sin-paciente"
            src="@assets/icons/icon-sinDatos.svg"
            alt=""
          />
          <span class="copie-opcion">Datos genéricos</span>
        </div>
        <div v-if="ultimoUsuario" class="opcion-realizar" @click="opcion(3)">
          <img
            class="icon-opcion-ultimo-paciente"
            src="@assets/icons/icon-ultimo_paciente.svg"
            alt=""
          />
          <span class="copie-opcion">Utilizar datos último paciente</span>
        </div>
      </div>
    </main>
    <Footer />
  </div>
  <AgregarPaciente v-if="showModal" @cerrar="cerrarModal" @regresar="cerrarModal" @agregado="agregado"> </AgregarPaciente>
</template>
<script>
import Footer from "@components/Layouts/FooterGeneral.vue";
import AgregarPaciente from "@components/Modal/AgregarPaciente.vue";
import { useUserStore } from "@store/userStore.js";

export default {
  name: "TipoPaciente",
  components: {
    Footer,
    AgregarPaciente,
  },
  emits: [],
  props: {},
  mixins: [],
  data() {
    return {
      showModal: false,
      useUser: useUserStore(),
      ultimoUsuario: false,
    };
  },
  beforeMount() {
    this.useUser.guardarRuta('TipoPaciente')
    if(this.useUser.ultimoPaciente) {
      this.ultimoUsuario = true
    }
  },
  computed: {},
  methods: {
    opcion(opcion) {
      if (opcion == 1) {
        this.showModal = true;
      } else if (opcion == 2) {
        this.useUser.guardarPaciente(false)
        this.useUser.existeUltimoPaciente(false) //Se cambia a false en cuanto selecciona que quiere que sea generica y no hasta finalizar
        this.$router.push("/servicios")
      } else if(opcion == 3) {
        this.$router.push("/servicios")
      }
    },
    cerrarModal() {
      this.showModal = false;
    },
    agregado() {
      this.showModal = false
      this.$router.push("/servicios")
    }
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.copiePregunta {
  display: flex;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  color: var(--primary-color);
  opacity: 1;
  padding: 30px 0;
  font-variant: normal;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0px;
  justify-content: center;
}
.opcion-realizar {
  width: 100%;
  height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px #70707038;
  cursor: pointer;
}
.icon-opcion {
  width: 53px;
  height: 53px;
  margin-bottom: 6px;
}
.icon-opcion-sin-paciente {
  width: 33px;
  height: 36px;
  margin-bottom: 6px;
}
.icon-opcion-ultimo-paciente {
  width: 47px;
  height: 51px;
  margin-bottom: 6px;
}
.copie-opcion {
  font-style: normal;
  font-variant: normal;
  font-weight: medium;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #050608;
}
.home {
  padding: 0px 40px;
  min-width: 95%;
}
</style>