import { createRouter, createWebHistory } from 'vue-router'
import Login from '@views/Login.vue'
import Home from "@views/Home.vue";
import TipoPaciente from "@views/TipoPaciente.vue";
import Servicios from "@views/Servicios.vue";
import Resumen from "@views/Resumen.vue";
import { useUserStore } from "@store/userStore.js";
import RecetaPDF from "@views/RecetaPDF.vue";

const routes = [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/tipopaciente',
      name: 'TipoPaciente',
      component: TipoPaciente,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/servicios',
      name: 'Servicios',
      component: Servicios,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/resumen',
      name: 'Resumen',
      component: Resumen,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/mireceta/:token',
      name: 'RecetaPDF',
      component: RecetaPDF,
      meta: {
        title: 'PDF',
        isPublic: true
      }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
  })

  router.beforeEach((to, from, next) => {
    const useUser = useUserStore();

    let title = to.meta.title || ''
    document.title = `Recetame | ${title}`
  
  
     const isPublic = to.meta.isPublic || false
  
     const isAuthenticated =  useUser.session || false;
  
     if (!isPublic && !isAuthenticated) {
       return next({ name: 'Login' })
     }
  
    if (to.name === 'Login' && isAuthenticated) {
       return next({ name: 'Home' })
     }

    //  if (from.path === '/' && to.name !== 'Home') {
    //   return next({ name: 'Home' })
    // }

    // if (useUser.ruta && from.path !== useUser.ruta) {
    //   return next({ path: useUser.ruta });
    // }

    // if(from.path == '/' && to.path !== '/home' ) {
    //   console.log('user', useUser.ruta)
    // console.log('to path', to.path)
    // console.log('from path', from.path)
    //   if(useUser.ruta && useUser.ruta.trim() != '') {
    //     return next({name: useUser.ruta})
    //   } else {
    //     return next({ name: 'Home' })
    //   }
    // }
  
    return next()
  })
  
  export default router