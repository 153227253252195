import api from '@services/api';
const service = {};
const URL = '/categories';


service.getCategorias = async () => {
  try {
    const categorias = await api.get(`${URL}/services`);
    return { error: false, categorias: categorias.data };
  } catch (error) {
    console.log(error)
    return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
  }
};
service.getProductos = async () => {
    try {
        const products = await api.get(`${URL}/products`);
        
        return { error: false, products: products.data };
      } catch (error) {
        console.log(error)
        return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
      } 
}
service.getServiciosById = async (id) => {
  try {
    const servicios = await api.get(`${URL}/${id}/services`)

    return {error: false, servicios: servicios}
  } catch (error) {
    console.log(error)
        return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
  }
}
export default service;