import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    session: null,
    user: null,
    token: null,
    opcionRealizar: null,
    paciente: null,
    ultimoPaciente: false,
    ruta: null,
  }),
  getters: {
    realizar: (state) => state.opcionRealizar,
    datosPaciente: (state) => state.paciente
  },
  actions: {
    guardarSession() {
      sessionStorage.setItem('session', JSON.stringify(this.session));
    },
    guardarUsuario() {
      sessionStorage.setItem('user', this.user);
    },
    guardarToken() {
      sessionStorage.setItem('token', this.token);
    },
    guardarOpcionRealizar(opcion) {
      this.opcionRealizar = opcion
    },
    guardarPaciente(paciente) {
      this.paciente = paciente
      sessionStorage.setItem('paciente', this.paciente);
    },
    setSession({session, user}) {
      this.session = session;
      this.token = session.accessToken;
      this.user = user
      this.guardarSession()
      // sessionStorage.setItem('session', JSON.stringify(session));
      this.guardarUsuario()
      // sessionStorage.setItem('user', user);
      this.guardarToken()
    },
    existeUltimoPaciente(existencia) {
      this.ultimoPaciente = existencia
      sessionStorage.setItem('ultimoPaciente', this.ultimoPaciente);
    },
    logout() {
      this.$reset();
      // this.user = null;
      // this.token = null;
      // this.session = null;
      this.token = null
      sessionStorage.removeItem('session');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('ultimoPaciente');
      sessionStorage.removeItem('token');
      this.guardarSession()
      this.guardarUsuario()
    },
    getUser() {
        return this.user
    },
    getSession() {
        return this.session
    },
    guardarRuta(path) {
      this.ruta = path
    },
    reiniciar() {
      console.log('reiniciar')
      this.paciente = null
      this.ultimoPaciente = false
      sessionStorage.removeItem('ultimoPaciente');
      sessionStorage.removeItem('paciente');
    }
  },
  persist: true,
});