<template>
  <div class="app-container">
    <main class="content">
      <div class="modal-overlay">
        <div class="modal">
          <div class="contenedor-cerrar" @click="closeModal">
            <i class="icon-cerrar"></i>
          </div>
          <p class="copie">Agregar {{ dato == 1 || dato == 2 ? 'teléfono' : 'correo electrónico' }}</p>
          <div class="entrada" v-if="dato == 1 || dato == 2">
            <!-- <span class="labelInput text weight-normal">Teléfono*</span> -->
            <InputMask v-model="telefono" class="input" type="text" id="inputTelefono" placeholder="(000) 000 0000"
              autocomplete="off" inputMode="numeric" :maxlength="15" @paste="validarNumero($event)"
              @keypress="validarNumero($event)" dataMaska="(###) ### ####" @numero="telefonoSinMascara"
              @blur="validarTelefono" />
          </div>
          <div class="entrada" v-if="dato == 3">
            <!-- <span class="labelInput text weight-normal">Correo electrónico</span> -->
            <Input v-model="email" class="input" type="text" id="inputApellidoPaterno" placeholder="ejemplo@gmail.com"
              :maxlength="50" autocomplete="off" inputmode="email" @blur="validarCorreo" />

          </div>
          <p class="error" v-if="error">{{ msj }}</p>
          <div class="contenedor-btn">
            <Button class="btn btn-aceptar" @click="confirmar">Confirmar</Button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Button from '@components/General/Form/Button.vue';
import Input from "@components/General/Form/Input.vue";
import InputMask from "@components/General/Form/InputMask.vue";
import controlMixin from "@mixins/control.js";

export default {
  name: 'modalFaltantePaciente',
  components: {
    Button,
    Input,
    InputMask
  },
  props: {
    dato: {
      //1: whatsapp, 2: sms, 3: email
      type: Number,
      default: 0
    },
    valor: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      telefono: "",
      email: '',
      msj: '',
      error: false,
    }
  },
  emits: ['cerrar', 'dato'],
  methods: {
    closeModal() {
      this.$emit('cerrar')
    },
    validarNumero(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^\d+$/.test(char)) return true;
      else e.preventDefault();
    },
    telefonoSinMascara(numero) {
      this.telefono = numero;
    },
    validarTelefono() {
      let valido = true
      if (!controlMixin.validarTelefono(this.telefono)) {
        valido = false;
      }
      return valido
    },
    validarCorreo() {
      let valido = true
      if (this.email != '' && this.email != null) {
        if (!controlMixin.validarFormatoCorreo(this.email?.toLowerCase().trim())) {
          valido = false;
        }
      }
      return valido
    },
    confirmar() {
      this.error = false
      this.msj = ''
      if(this.dato == 1 || this.dato == 2) {
        if(!this.validarTelefono()) {
          this.msj = 'Introduce un teléfono valido'
          this.error = true
        } else {
          this.$emit('dato',{dato: this.dato, valor: this.telefono})
        }
      } else {
        if(!this.validarCorreo()) {
          this.msj = 'Introduce un correo valido'
          this.error = true
        } else {
          this.$emit('dato',{dato: this.dato, valor: this.email})
        }
      }
    }
  },
  watch: {
    telefono() {
      this.msj = ''
      this.error = false
    },
    email() {
      this.msj = ''
      this.error = false
    }
  }
}
</script>
<style scoped>
.modal-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1006;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  overflow: hidden;
}

.modal {
  width: 80%;
  height: 220px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 1007;
  position: relative;
  overflow-y: hidden;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.copie {
  font: normal normal normal 18px / 19px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 30px;
}

.contenedor-cerrar {
  display: flex;
  float: inline-end;
}
.contenedor-btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.btn-aceptar {
  width: 90%;
  height: 45px;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  border: 0;
}
.error {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: var(--error-color);
  opacity: 1;
}
@media (min-width: 767px) {
  .modal-overlay {
    width: 60%;
  }
}

@media (min-width: 1279px) {
  .modal-overlay {
    width: 40%;
  }
}
</style>