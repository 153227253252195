<template>
  <div class="contenedor-cards">
    <div v-show="load" :class="load ? 'card-load': ''" v-for="i in 7" :key="i">
        <Skeleton  height="68px"></Skeleton>
    </div>
    <div v-for="(item, index) in estudios" :key="index" class="card">
      <div class="card-header" @click="toggleItem(index)">
        <div class="seccion-izquierda">
          <i
            class="icon-flecha"
            :class="{ rotated: expandedItem === index }"
          ></i>
          <div class="name-price">
            <span class="title">{{ item.name }}</span>
            <span class="price"
              ><span class="moneda">$</span>
              <span class="cantidad">{{ getTotalEntero(item) }}</span>
              <span class="decimales">.{{getTotalDecimal(item)}}</span></span
            >
          </div>
        </div>
        <div class="seccion-derecha" v-if="opcionRealizar == 1">
          <i class="icon-addcarrito" @click.stop="handleCartClick(item)"></i>
        </div>
      </div>
      <div v-if="expandedItem === index" class="card-collapse">
        <span class="separador-linea"></span>
        <p class="title-collapse">Preparación</p>
        <p class="descripcion-collapse">{{ item.preparation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {useCarritoStore} from '@store/carritoStore'
import { useUserStore } from "@store/userStore.js";
import monto from '@mixins/monto.js'
import Skeleton from "@components/General/Load/Skeleton.vue";

export default {
  name: "EstudiosView",
  components: {
    Skeleton,
  },
  props: {
    estudios: {
      type: Array,
      required: true,
    },
    load: {
      type: Boolean,
      
    },
  },
  data() {
    return {
      expandedItem: null,
      cartStore: useCarritoStore(),
      useUser: useUserStore()
    };
  },
  methods: {
    toggleItem(index) {
      this.expandedItem = this.expandedItem === index ? null : index;
    },
    handleCartClick(item) {
      this.cartStore.addItem(item);
      this.$emit('agregar')
    },
    getTotalEntero(item){
      let cantidadAux = item.cantidad ? item.cantidad : 1
      let total = +item.price * +cantidadAux
      return monto.splitNumber(total).integer
    },
    getTotalDecimal(item) {
      let cantidadAux = item.cantidad ? item.cantidad : 1
      let total = +item.price * +cantidadAux
      return monto.splitNumber(total).decimal
    },
  },
  computed: {
    opcionRealizar() {
      return this.useUser.realizar
    }
  }
};
</script>
<style scoped>
.contenedor-cards {
  max-width: 100%;
  margin: 0 auto;
}

.card {
  margin-bottom: 10px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
.card-load {
  margin: 10px 10px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  align-items: center;
}

.seccion-izquierda {
  display: flex;
  align-items: center;
}

.icon-flecha {
  width: 12px;
  height: 12px;
  color: #c4c4c4;
  opacity: 1;
  font-size: 12px;
  transition: transform 0.3s ease;
}

.rotated {
  transform: rotate(90deg);
}
.title {
  margin-left: 10px;
  font-size: 16px;
  text-align: left;
  font: normal normal 500 16px/21px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
}
.price {
  margin-left: 10px;
  text-align: left;
  margin-top: 5px;
}
.seccion-derecha {
  display: flex;
  align-items: center;
}

.card-collapse {
  padding: 0px 15px 15px 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.74;
  transition: transform 0.3s ease;
}
.name-price {
  display: flex;
  flex-direction: column;
}
.icon-addcarrito {
  width: 27px;
  height: 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color-2c);
  border-radius: 4px;
  opacity: 1;
  color: black;
  align-items: center;
  display: flex;
}
.moneda,
.decimales {
  font: normal normal bold 15px/20px Roboto;
  letter-spacing: 0px;
  color: var(--secondary-color);
}

.cantidad {
  font: normal normal bold 20px/20px Roboto;
  letter-spacing: 0px;
  color: var(--secondary-color);
}
.title-collapse {
  font: normal normal 500 14px/19px Roboto;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  margin-top: 10px;
}
.descripcion-collapse {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.74;
}
.separador-linea {
  height: 1px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #707070;
  opacity: 0.14;
}
</style>