const control = {}

control.validarFormatoCorreo =  value => {
  let patronCorreo = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

  return patronCorreo.test(value)
}

control.validarTelefono = value => {
  let pattern = /\D/g
  let telefono = value.replace(pattern, '')

  return telefono.length == 10
}

export default control