const monto = {}

monto.splitNumber = value => {
  let truncatedValue = parseFloat(value).toFixed(2);

  let [integer, decimal] = String(truncatedValue).split('.');

  decimal = decimal ? decimal.padEnd(2, '0') : '00';

  integer = parseInt(integer).toLocaleString('es-MX');

  return {
    integer,
    decimal,
    formatted: `$${integer}.${decimal}`
  };
}

monto.formatPrice =  (value, precision = 2, expresion = /(-?\d*.\d{0,2})/) => {
  if (value == null || value == '') {
    value = '0.00'
  }
  
  let t = value.toString()
  let regex = new RegExp(expresion)

  let valorAux = regex.exec(t)

  if (!valorAux) {
    valorAux = parseFloat('0.00').toFixed(precision)
  } else {
    valorAux = parseFloat(valorAux[0]).toFixed(precision)
  }

  let valorFinal = valorAux.split('.')[0]
  let valorFinalDecimal = valorAux.split('.')[1]

  return `${valorFinal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${valorFinalDecimal}`
}

export default monto