<template>
  <div class="app-container">
    <main class="content">
      <div class="modal-overlay">
        <div class="modal">
          <Vue3Lottie :animationData="AnimacionJSON" :height="87" :width="87" />
          <p class="copie-alta">Has agregado 1 producto a tu carrito.</p>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie'

export default {
  name: 'modalAltaCarrito',
  components: {
    Vue3Lottie
  },
  data() {
    return {
      AnimacionJSON: require('../../assets/animacion-carrito.json'),
    }
  }
}
</script>
<style scoped>
.modal-overlay {
  position: absolute; 
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1006; 
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  overflow: hidden;
}

.modal {
  width: 80%;
  height: 135px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 1007;
  position: relative;
  overflow-y: hidden;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
.copie-alta {
  font: normal normal normal 14px / 19px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  text-align: center;
  margin-top: 10px;
}
@media (min-width: 767px) {
  .modal-overlay {
    width: 60%;
  }
}
@media (min-width: 1279px) {
  .modal-overlay {
    width: 40%;
  }
}
</style>