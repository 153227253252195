<template>
  <ModalBase class="" :mostrarHeader="true" @cerrar="closeModal">
    <div class="registro">
      <p class="title text weight-bold">Agregar paciente</p>
      <div class="entrada">
        <span class="labelInput text weight-normal">Teléfono*</span>
        <InputMask
          v-model="telefono"
          class="input"
          type="text"
          id="inputTelefono"
          placeholder="(000) 000 0000"
          autocomplete="off"
          inputMode="numeric"
          :maxlength="15"
          @paste="validarNumero($event)"
          @keypress="validarNumero($event)"
          dataMaska="(###) ### ####"
          @numero="telefonoSinMascara"
          @blur="focusTelefono"
        />
      </div>
      <div class="entrada">
        <span class="labelInput text weight-normal">Nombre*</span>
        <Input
          v-model="nombre"
          class="input"
          type="text"
          id="inputNombre"
          placeholder="Ingresar"
          :maxlength="50"
          autocomplete="off"
          inputmode="text"
        />
      </div>

      <div class="apellidos">
        <div class="entrada apellido">
          <span class="labelInput text weight-normal">Apellido paterno*</span>
          <Input
            v-model="apellidoPaterno"
            class="input"
            type="text"
            id="inputApellidoPaterno"
            placeholder="Ingresar"
            :maxlength="50"
            autocomplete="off"
            inputmode="text"
          />
        </div>

        <div class="entrada apellido">
          <span class="labelInput text weight-normal">Apellido materno</span>
          <Input
            v-model="apellidoMaterno"
            class="input"
            type="text"
            id="inputApellidoMaterno"
            placeholder="Ingresar"
            :maxlength="50"
            autocomplete="off"
            inputmode="text"
          />
        </div>
      </div>
      <div class="entrada">
        <span class="labelInput text weight-normal">Fecha nacimiento*</span>
        <Calendario @actualizarFecha="cambioFecha" :fechaEditar="fechaEditar"></Calendario>
      </div>
      <span class="labelInput text weight-normal">Sexo*</span>
      <div class="sexo" id="sexo">
        <RadioButton
          :activo="false"
          id="femenino"
          v-model="selected"
          valueEntrada="2"
          nameEntrada="selected"
          width="calc(50% - 12px)"
          marginRight="18px"
          label="Femenino"
          @change="seleccion"
        >
        </RadioButton>
        <RadioButton
          id="masculino"
          :activo="false"
          v-model="selected"
          valueEntrada="1"
          nameEntrada="selected"
          width="calc(50% - 12px)"
          label="Masculino"
          @change="seleccion"
        ></RadioButton>
      </div>

      <div class="entrada" style="margin-bottom: 28px">
        <span class="labelInput text weight-normal">Correo electrónico</span>
        <Input
          v-model="email"
          class="input"
          type="text"
          id="inputApellidoPaterno"
          placeholder="ejemplo@gmail.com"
          :maxlength="50"
          autocomplete="off"
          inputmode="email"
        />
      <span class="error-form" v-if="mensajeDatos">*Favor de introducir los campos obligatorios</span>
      </div>
      <div class="botones">
        <Button id="btnRegresar" class="btn btn-regresar" @click="regresar"
          >Regresar</Button
        >
        <Button id="btnContinuar" class="btn btn-continuar" @click="continuar"
          >Continuar</Button
        >
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from "@components/General/Modal/ModalBase.vue";
import Input from "@components/General/Form/Input.vue";
import InputMask from "@components/General/Form/InputMask.vue";
import RadioButton from "@components/General/Form/RadioButton.vue";
import Calendario from "@components/General/Form/Calendario.vue";
import Button from "@components/General/Form/Button.vue";
import { useUserStore } from "@store/userStore.js";
import controlMixin from "@mixins/control.js";
import PacienteService from "@services/paciente.js"
import moment from "moment";

export default {
  name: "ModalPaciente",
  components: {
    ModalBase,
    Input,
    RadioButton,
    Calendario,
    Button,
    InputMask,
  },
  props: {
    mensajeEntrada: {
      type: String,
      default: "",
    },
    editar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      telefono: "",
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: null,
      email: null,
      useUser: useUserStore(),
      mensajeDatos: null,
      fechaEditar: '',
      idPaciente: null
    };
  },
  emits: ["cerrar", "regresar", "agregado"],
  beforeMount() {
    if (this.editar) {
      let data = this.useUser.datosPaciente;
      this.telefono = data.telefono;
      this.nombre = data.nombre;
      this.apellidoPaterno = data.apellidoPaterno;
      this.apellidoMaterno = data.apellidoMaterno ? data.apellidoMaterno : "";
      this.fechaNacimiento = data.fechaNacimiento;
      this.selected = data.sexo;
      this.email = data.correo;
      this.fechaEditar = this.fechaNacimiento      
    } else {
      this.fechaEditar = ''
    }
  },
  methods: {
    closeModal() {
      this.$emit("cerrar");
    },
    seleccion() {
      console.log("");
    },
    regresar() {
      this.$emit("regresar");
    },
    continuar() {
      if (this.validarDatos()) {
        let data = {
          telefono: this.telefono,
          nombre: this.nombre,
          apellidoPaterno: this.apellidoPaterno,
          apellidoMaterno: this.apellidoMaterno,
          fechaNacimiento: this.fechaNacimiento,
          sexo: this.selected,
          correo: this.email,
          id: this.idPaciente
        };
        this.useUser.guardarPaciente(data);
        this.$emit("agregado");
      } else {
        this.mensajeDatos = true
      }
    },
    cambioFecha(fecha) {
      this.fechaNacimiento = fecha;
    },
    validarNumero(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^\d+$/.test(char)) return true;
      else e.preventDefault();
    },
    telefonoSinMascara(numero) {
      this.telefono = numero;
    },
    validarDatos() {
      let valido = true;
      if (!controlMixin.validarTelefono(this.telefono)) {
        valido = false;
      }
      if (this.nombre.length == 0 || this.nombre == "" || this.nombre == null) {
        valido = false;
      }
      if (this.apellidoPaterno.length == 0 || this.apellidoPaterno == "" || this.apellidoPaterno == null) {
        valido = false;
      }
      if (this.fechaNacimiento == "" || this.fechaNacimiento == null || this.fechaNacimiento == 'Invalid Date') {
        valido = false;
      }
      if (this.selected == "" || this.selected == null) {
        valido = false;
      }
      if (this.email != '' && this.email != null) {
        if(!controlMixin.validarFormatoCorreo(this.email?.toLowerCase().trim())) {
          valido = false;
        }
      }
      this.mensajeDatos = !valido
      return valido;
    },
    async focusTelefono() {
      this.limiarDatos()
      const res = await PacienteService.getPacienteByTelefono(this.telefono)
      if(!res.error && res.paciente != '') {
        let paciente = res.paciente
        this.nombre = paciente.name
        this.apellidoPaterno = paciente.lastname_p
        this.apellidoMaterno = paciente.lastname_m
        this.selected = paciente.sex
        this.fechaNacimiento = moment(paciente.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        this.fechaEditar = moment(paciente.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        this.idPaciente = paciente.id
        this.email = paciente.email
      }
    },
    limiarDatos() {
      this.selected = null
      this.nombre = ""
      this.apellidoPaterno = ""
      this.apellidoMaterno = ""
      this.fechaNacimiento = ''
      this.email = null
      this.fechaEditar = ''
      this.idPaciente = null
    }
  },
  watch: {
    nombre() {
      this.mensajeDatos = null
    },
    apellidoPaterno() {
      this.mensajeDatos = null
    },
    apellidoMaterno() {
      this.mensajeDatos = null
    },
    telefono() {
      this.mensajeDatos = null
    },
    email() {
      this.mensajeDatos = null
    },
    selected() {
      this.mensajeDatos = null
    },
    fechaNacimiento() {
      this.mensajeDatos = null
    }
  }
};
</script>

<style scoped>
.labelInput {
  text-align: left;
  opacity: 1;
  align-items: flex-start;
  display: flex;
  width: 100%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
}
.text {
  font-style: normal;
  font-variant: normal;
}
.weight-normal {
  font-weight: normal;
}
.weight-bold {
  font-weight: bold;
}
.input {
  width: 100%;
  margin-bottom: 21px;
}
.registro {
  padding: 20px;
}
.title {
  text-align: left;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 30px;
}
.apellido {
  width: 45%;
}
.apellidos {
  display: flex;
  justify-content: space-between;
}
.custom-radio {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

input[type="radio"]:checked + .custom-radio {
  background: #68cbeb29 0% 0% no-repeat padding-box;
  border: 1px solid #70707043;
  border-radius: 4px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.sexo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
}
.labelradio {
  display: flex;
}
.botones {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn {
  width: 299px;
  height: 45px;
  letter-spacing: 0px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 15px;
}
.btn-regresar {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  opacity: 1;
  background-color: #fff;
}
.btn-continuar {
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  border: 0;
}
.error-form {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: var(--error-color);
  opacity: 1;
}
</style>
