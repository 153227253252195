<template>
  <div class="contenedor-cards" style="height: calc(100vh - 362px);">
    <div v-for="(item, index) in resumen" :key="index" class="card">
      <div class="card-header">
        <div class="name-price">
          <span class="title">{{ item.name }}</span>
          <span class="price"
            ><span class="moneda">$</span>
            <span class="cantidad">{{ getTotalEntero(item) }}</span>
            <span class="decimales">.{{getTotalDecimal(item)}}</span></span
          >
        </div>
        <span class="cantidad-servicios">Cantidad {{item.cantidad}}</span><br />
        <p class="lbl-preparacion">Preparación</p>
        <p class="msj-preparacion">
          {{item.preparation}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useCarritoStore } from "@store/carritoStore";
import { useUserStore } from "@store/userStore.js";
import monto from '@mixins/monto.js'

export default {
  name: "CardResumen",
  props: {},
  data() {
    return {
      expandedItem: null,
      cartStore: useCarritoStore(),
      useUser: useUserStore(),
    };
  },
  methods: {
    // toggleItem(index) {
    //   this.expandedItem = this.expandedItem === index ? null : index;
    // },
    // handleCartClick(item) {
    //   this.cartStore.addItem(item);
    //   this.$emit('agregar')
    // },
    getTotalEntero(item){
      let total = +item.price * +item.cantidad
      return monto.splitNumber(total).integer
    },
    getTotalDecimal(item) {
      let total = +item.price * +item.cantidad
      return monto.splitNumber(total).decimal
    },
  },
  beforeMount() {},
  computed: {
    resumen() {
      return this.cartStore.cartItems;
    },
  },
};
</script>
<style scoped>
.contenedor-cards {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  overflow-y: auto;
}

.card {
  margin-bottom: 10px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  min-height: 189px;
}

.card-header {
  padding: 15px;
  cursor: pointer;
}
.title {
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
}
.price {
  margin-left: 10px;
  text-align: left;
  margin-top: 5px;
}

.name-price {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.icon-addcarrito {
  width: 27px;
  height: 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color-2c);
  border-radius: 4px;
  opacity: 1;
  color: black;
  align-items: center;
  display: flex;
}
.moneda,
.decimales {
  font: normal normal bold 15px/20px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.cantidad {
  font: normal normal bold 20px/20px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
}
.cantidad-servicios {
  text-align: left;
  font: normal normal 500 12px/16px Roboto;
  letter-spacing: 0px;
  color: #898989;
  opacity: 1;
}
.lbl-preparacion {
  font: normal normal bold 14px/19px Roboto;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-top: 15px;
}
.msj-preparacion {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.74;
}
</style>