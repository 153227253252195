<template>
  <button class="btn" :class="getClass" :disabled="isDiasable" @click="action">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    isDiasable: {
      type: Boolean,
      default: false,
    },
    sombras: {
      type: Boolean,
      default: false,
    },
    descarga: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClass() {
      return {
        'btn-sombra': this.sombras,
      }
    },
  },
  methods: {
    action() {
      if (this.descarga != false) {
        this.$emit('descargar')
      }
    },
  },
}
</script>

<style scoped>
.btn-activo {
  min-width: 100%;
  height: 45px;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}
.btn-negro:hover {
  background-color: #1a1a1a;
}
.btn-negro:active {
  background-color: #0a0a0a;
}
.btn-desactivar {
  background-position: center;
}
.btn-desactivar:hover {
  background-position: center;
}
.btnDesactivado {
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}
.btn-sombra {
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}


</style>
