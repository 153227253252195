<template>
  <div class="calendar-input-wrapper">
    <input
      v-model="dateInput"
      @input="updateDate"
      :placeholder="placeholder"
      type="text"
      readonly
      class="date-input"
    />
    <button @click="toggleCalendar" class="calendar-icon">
      <img src="@assets/icons/icon-calendar.svg" alt="" />
    </button>
    <div v-if="isCalendarVisible" class="calendar-popover">
      <vc-date-picker
        v-model="selectedDate"
        mode="single"
        @update:model-value="updateDateFromPicker"
      />
    </div>
  </div>
</template>

<script>
import {isValid, parse} from 'date-fns';
export default {
  data() {
    return {
      dateInput: "",
      selectedDate: null,
      isCalendarVisible: false,
      placeholder: "DD/MM/AAAA",
    };
  },
   props: {
    fechaEditar: {
      type: String,
      default: ''
    }
  },
  emits: ['actualizarFecha'],
  beforeMount() {
    if(this.fechaEditar) {
      if (this.isDateValid(this.fechaEditar)) {
        this.dateInput = this.fechaEditar;
        this.selectedDate = this.parseDate(this.fechaEditar);
      }
    }
  },
  methods: {
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    updateDate() {
      const [day, month, year] = this.dateInput.split("/");
      this.selectedDate = new Date(`${year}-${month}-${day}`);
      this.$emit('actualizarFecha', this.selectedDate);
    },
    updateDateFromPicker(value) {
      const day = String(value.getDate()).padStart(2, "0");
      const month = String(value.getMonth() + 1).padStart(2, "0");
      const year = value.getFullYear();
      this.dateInput = `${day}/${month}/${year}`;
      this.isCalendarVisible = false;
      this.$emit('actualizarFecha', this.dateInput);
    },
    isDateValid(dateStr) {
      const parsedDate = this.parseDate(dateStr);
      return isValid(parsedDate) && dateStr.match(/^\d{2}\/\d{2}\/\d{4}$/);
    },
    parseDate(dateStr) {
      return parse(dateStr, 'dd/MM/yyyy', new Date());
    },
  },
  watch: {
    fechaEditar() {
      if (this.fechaEditar && this.fechaEditar != '') {
        if (this.isDateValid(this.fechaEditar)) {
          this.dateInput = this.fechaEditar;
          this.selectedDate = this.parseDate(this.fechaEditar);
        }
      } else {
        this.dateInput = null
      }
    }
  }
};
</script>

<style scoped>
.calendar-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;
}

.date-input {
  width: 100%;
  padding-right: 30px;
  padding-left: 5px;
  border: 0px;
  outline: none;
  height: 45px;
  width: 100%;
  border-radius: 4px;
  padding: 0px 15px;
  color: #707070;
  letter-spacing: 0px;
  color: #707070;
  opacity: 0.9;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 20px;
}

.calendar-popover {
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
<style>
  .vc-bordered {
    width: 100% !important;
  }
</style>