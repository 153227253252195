<template>
  <div v-if="visible" class="floating-menu">
    <div class="opciones">
      <div
        class="item"
        v-for="option in options"
        :key="option.label"
        @click="option.handler"
      >
          <i :class="'ic icon-' + option.icon"></i>
          <span class="item-label">{{ option.label }}</span>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "FloatingMenu",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    position: {
      type: Object,
      default: () => ({ top: 0, left: 0 }),
    },
  },
};
</script>
  
  <style>
.floating-menu {
  position: absolute;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  top: 64px;
  right: 20px;
  z-index: 3;
  border-radius: 10px;
  border: 2px solid #d7eef5;
  width: 258px;
  height: 124px;
  background: #d7eef5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.floating-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #d7eef5;
}
.item {
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
}
.item-label {
  font: normal normal 500 18px/33px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  margin-left: 5px;
}
.ic {
  font-size: 22px;
  color: var(--primary-color);
}
</style>