<template>
  <div class="container">
    <div class="modal">
      <div class="mask"></div>
      <div class="contenido-modal" :style="[newStyle]" v-bind:class="modalCarrito ? 'fondo-carrito-modal' : ''">
        <div class="modal-header" v-if="mostrarHeader">
          <div id="cerrar-movil" class="cerrar-header" v-bind:class="modalCarrito ? '' : 'sombra-header'">
            <div class="contenedor-cerrar" v-if="mostrarCerrar" @click="closeModal">
              <i class="icon-cerrar"></i><span class="copie-cerrar">Cerrar</span>
            </div>
          </div>
        </div>
        <div class="cuerpo-modal" :style="getStyle">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalBase",
  props: {
    mostrarHeader: {
      type: Boolean,
      default: true,
    },
    mostrarCerrar: {
      type: Boolean,
      default: true,
    },
    overflow_y: {
      type: Boolean,
      default: true,
    },
    newStyle: Array,
    modalCarrito: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key_random: 0,
    };
  },
  emits: ["cerrar"],
  methods: {
    closeModal() {
      this.$emit("cerrar");
    },
  },
  computed: {
    getStyle() {
      const style = this.overflow_y ? `overflow-y: auto` : "overflow-y: unset";

      return style;
    },
  },
  mounted() {
    const random = Math.floor(Math.random() * (100000 - 1000)) + 1000;
    this.key_random = random;
    document.body.classList.add("modal_" + random);
  },
  unmounted() {
    document.body.classList.remove("modal_" + this.key_random);
  },
};
</script>

<style scoped>
/* MODAL*/
.modal {
  display: none;
  position: fixed;
  z-index: 1005;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}
.mask {
  background-color: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.contenido-modal {
  position: relative;
  background-color: #ffffff;
  margin: auto;
  min-width: 300px;
  box-shadow: 0px 3px 6px #00000029;
  /* -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s; */
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.fondo-carrito-modal {
  background-color: var(--primary-color);
}
.modal-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  position: relative;
}
@-webkit-keyframes animatetop {
  from {
    top: -500px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -500px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.contenido-modal .cuerpo-modal {
  overflow-y: auto;
  width: 100%;
}

.cuerpo-modal {
  flex: 1;
  border-radius: 0;
  padding: 0;
}
.cerrar-header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  margin-bottom: 34px;
}
.sombra-header {
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}
.contenedor-cerrar {
  cursor: pointer;
  display: flex;
}
.icon-cerrar {
  justify-content: flex-end;
  margin-right: 10px;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-size: 14px;
}
.copie-cerrar {
  margin-right: 10px;
  letter-spacing: 0px;
  color: #FFFFFF;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
@media (min-width: 767px) {
  .contenido-modal {
    width: 60%;
    border-radius: 10px;
  }
  .icon-cerrar {
    cursor: pointer;
  }
}
@media (min-width: 1279px) {
  .contenido-modal {
    width: 40%;
  }
}
</style>
