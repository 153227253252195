<template>
  <div class="contenedor-datos">
    <span class="nombre">{{ getNombre }}</span>
    <div class="fecha-editar">
      <p class="fecha">
        <img src="@assets/icons/icon-calendar.svg" alt="" />{{
          datos.fechaNacimiento
        }}
      </p>
      <span class="genero"
        ><i
          :class="
            datos.sexo == 2 ? 'icon-femenino2' : 'icon-masculino1'
          "
        ></i>
        {{ datos.sexo == 2 ? 'Femenino' : 'Masculino'}}</span
      >
      <i class="icon-editardatos" @click="editar"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContenedorDatos",
  props: {
    datos: {
      type: Object,
      required: true,
    },
  },
  emits: ['editar'],
  computed: {
    getNombre() {
      let nombre =
        this.datos.nombre +
        " " +
        (this.datos?.apellidoPaterno ? this.datos?.apellidoPaterno : "") +
        " " +
        (this.datos?.apellidoMaterno ? this.datos?.apellidoMaterno : "");

      return nombre;
    },
  },
  methods: {
    editar() {
      this.$emit('editar')
    }
  }
};
</script>

<style scoped>
.contenedor-datos {
  height: 58px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin: 11px 20px 0px;
  padding: 10px;
}
.nombre {
  font: normal normal 500 16px/8px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  text-transform: capitalize;
}
.fecha-editar {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fecha {
  font: normal normal 500 12px/8px Roboto;
  letter-spacing: 0px;
  color: #7b7b7b;
  opacity: 1;
  display: flex;
  align-items: center;
}
.fecha > img {
  width: 10px;
  height: 11px;
  object-fit: cover;
  margin-right: 5px;
}
.genero {
  font: normal normal 500 12px/8px Roboto;
  letter-spacing: 0px;
  color: #7b7b7b;
  opacity: 1;
}
.genero > i {
  color : var(--secondary-color);
}
.icon-editardatos {
  font-size: 10px;
  color: #1035d3;
}
</style>