import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import  VueHtmlToPaper from './/VueHtmlToPaper'

import '@assets/iconfonts/icons-recetame.css'
import '@assets/fonts/Roboto-300.woff2'
import '@assets/fonts/Roboto-400.woff2'
import '@assets/fonts/Roboto-500.woff2'
import '@assets/fonts/Roboto-700.woff2'

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate); 

createApp(App).use(pinia).use(router).use(VueHtmlToPaper).use(VCalendar, { componentPrefix: 'vc', }).mount('#app')
