<template>
  <div class="contenedor-footer">
    <div class="footer-general">
      <img class="logo-footer" src="@assets/logo-recetame.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterGeneral",
};
</script>

<style scoped>
.contenedor-footer {
  width: 100%;
  display: flex;
  margin: auto;
  transform: translateX(0px);
  position: fixed;
  bottom: 0;
}
.footer-general {
  width: 100%;
  min-height: 100px;
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 5;
  padding: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  inset-area: y-start;
}
.logo-footer {
  width: 197px;
  height: 55px;
}

@media (min-width: 767px) {
  .footer-general {
    width: 60%;
  }
}
@media (min-width: 1279px) {
  .footer-general {
    width: 40%;
  }
}
</style>