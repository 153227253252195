<template>
  <ModalBase class="" :mostrarHeader="true" @cerrar="closeModal">
    <div class="content">
      <p class="copie">Escanéa código QR</p>
      <div class="qr-border">
        <div class="top-left"></div>
        <div class="top-right"></div>
        <qrcode-vue
          :value="qrValue"
          :size="200"
          :level="'H'"
          foreground="#13328A"
          :render-as="'svg'"
        />
        <div class="bottom-left"></div>
        <div class="bottom-right"></div>
      </div>
    </div>
    <Footer />
  </ModalBase>
</template>

<script>
import ModalBase from "@components/General/Modal/ModalBase.vue";
import QrcodeVue from "qrcode.vue";
import Footer from "@components/Layouts/FooterGeneral.vue";

export default {
  name: "ModalQR",
  components: {
    ModalBase,
    QrcodeVue,
    Footer,
  },
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      qrValue: this.link,
      foregroundColor: "#FF4141",
      //getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim(), // Color azul del código QR (cambia según necesites)
      backgroundColor: "#ffffff", 
    };
  },
  emits: ['cerrar'],
  beforeMount() {},
  methods: {
    closeModal() {
      this.$emit("cerrar");
    },
  },
  watch: {},
};
</script>

<style scoped>
.content {
  z-index: 1004;
}
.modal {
  z-index: 1005;
}
.copie {
  font: normal normal normal 22px/29px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  text-align: center;
  margin-bottom: 70px;
}
.qr-border {
  margin: auto !important;
  position: relative;
  width: 260px; /* Ancho del cuadro */
  height: 260px; /* Alto del cuadro */
  /* margin: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right,
.top-left,
.bottom-left,
.bottom-right {
  content: "";
  position: absolute;
  width: 45px; 
  height: 45px; 
  border-color: var(--secondary-color);
  border-width: 10px; 
  border-style: solid;
}

.top-left {
  top: 0;
  left: 0;
  border-top-width: 10px;
  border-left-width: 10px;
  border-right: none;
  border-bottom: none;
  border-radius: 5px 0px 0px 0px;
}
.top-right {
  top: 0;
  right: 0;
  border-top-width: 10px;
  border-right-width: 10px;
  border-left: none;
  border-bottom: none;
  border-radius: 0px 5px 0px 0px;
}
.bottom-left {
  bottom: 0;
  left: 0;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-right: none;
  border-top: none;
  border-radius: 0px 0px 0px 5px;
}

.bottom-right {
  bottom: 0;
  right: 0;
  border-bottom-width: 10px;
  border-right-width: 10px;
  border-left: none;
  border-top: none;
  border-radius: 0px 0px 5px 0px;
}
</style>
