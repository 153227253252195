<template>
  <div class="app-container">
    <main class="content">
      <div class="home">
        <span class="copiePregunta">No agregaste datos del paciente.</span>
        <span class="lbl-pregunta">¿Deseas continuar?</span>
        <div class="opcion-realizar" @click="opcion(2)">
          <img
            class="icon-opcion-sin-paciente"
            src="@assets/icons/icon-sinDatos.svg"
            alt=""
          />
          <span class="copie-opcion">Si, continuar sin datos</span>
        </div>
        <div class="opcion-realizar" @click="opcion(1)">
          <img
            class="icon-opcion"
            src="@assets/icons/icon-addPaciente.svg"
            alt=""
          />
          <span class="copie-opcion">No, agregar paciente</span>
        </div>
      </div>
    </main>
    <Footer />
  </div>
  <AgregarPaciente
    v-if="showModal"
    @cerrar="cerrarModal"
    @regresar="cerrarModal"
    @agregado="agregado"
  >
  </AgregarPaciente>
</template>
<script>
import Footer from "@components/Layouts/FooterGeneral.vue";
import AgregarPaciente from "@components/Modal/AgregarPaciente.vue";
import { useUserStore } from "@store/userStore.js";

export default {
  name: "ConfirmacionPaciente",
  components: {
    Footer,
    AgregarPaciente,
  },
  emits: ['confirmado'],
  props: {},
  mixins: [],
  data() {
    return {
      showModal: false,
      useUser: useUserStore(),
    };
  },
  beforeMount() {},
  computed: {},
  methods: {
    opcion(opcion) {
      if (opcion == 1) {
        this.showModal = true;
      } else if (opcion == 2) {
        this.useUser.guardarPaciente(false);
        this.$emit('confirmado')
        // this.$router.push("/servicios").then(() => {
        // // Reemplaza el estado actual en el historial con la nueva ruta /resumen
        //   // window.history.replaceState({}, '', '/servicios');
        // });
      }
    },
    cerrarModal() {
      this.showModal = false;
    },
    agregado() {
      this.showModal = false;
      this.$emit('confirmado')
      // this.$router.push("/servicios")
      // .then(() => {
      //   // Reemplaza el estado actual en el historial con la nueva ruta /resumen
      //   window.history.replaceState({}, '', '/servicios');
      // });
    },
  },
  created() {},
  unmounted() {},
  watch: {},
};
</script>
<style scoped>
.copiePregunta {
  display: flex;
  padding: 40px 0px 0px;
  justify-content: center;
  text-align: center;
  font: normal normal 900 22px/29px Roboto;
  letter-spacing: 0px;
  color: var(--secondary-color);
  opacity: 1;
}
.lbl-pregunta {
  text-align: center;
  font: normal normal 500 22px/29px Roboto;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  display: flex;
  justify-content: center;
}
.opcion-realizar {
  width: 100%;
  height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px #70707038;
  cursor: pointer;
}
.icon-opcion {
  width: 53px;
  height: 53px;
  margin-bottom: 6px;
}
.icon-opcion-sin-paciente {
  width: 33px;
  height: 36px;
  margin-bottom: 6px;
}
.copie-opcion {
  font-style: normal;
  font-variant: normal;
  font-weight: medium;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #050608;
}
.home {
  padding: 0px 40px;
  min-width: 95%;
}
</style>