import api from '@services/api';
const service = {};
const URL = '/order';


service.create = async (data) => {
  try {
    const registro = await api.post(`${URL}/services`, data);
    return { error: false, registro: registro.data };
  } catch (error) {
    console.log(error)
    return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
  }
};

service.getOrderId = async (id) => {
    try {
      const order = await api.get(`${URL}/${id}`);
      return { error: false, order: order.data };
    } catch (error) {
      console.log(error)
      return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
    }
  };

  service.sendSms = async (orderId) => {
    try {
      const sms = await api.post(`${URL}/sms`, orderId);
      return { error: false, sms: sms.data };
    } catch (error) {
      console.log(error)
      return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
    }
  };

  service.sendEmail = async (orderId) => {
    try {
      const email = await api.post(`${URL}/email`, orderId);
      return { error: false, email: email.data };
    } catch (error) {
      console.log(error)
      return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
    }
  };

  service.whatsapp = async (data) => {
    try {
      const whatsapp = await api.post(`${URL}/whatsapp`, data)
      return { error: false, whatsapp: whatsapp.data };
    } catch (error) {
      console.log(error)
      return { error: true, msj: error?.response?.data?.message ? error.response.data.message : 'Ocurrió un error, intentelo de nuevo.' };
    }
  }
export default service;