<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  mounted() {
    let lastTouchEnd = 0;

    document.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault(); // Prevenir zoom por pellizco
        }
      },
      { passive: false }
    );

    document.addEventListener(
      "touchend",
      function (event) {
        const now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault(); // Prevenir zoom por doble toque
        }
        lastTouchEnd = now;
      },
      false
    );
  },
};
</script>

<style>
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("@assets/fonts/Roboto-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("@assets/fonts/Roboto-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("@assets/fonts/Roboto-500.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("@assets/fonts/Roboto-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto";
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
html {
  font-size: 16px;
  min-width: 320px;
  background: url("./assets/fondo_recetame.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.vista {
  display: flex;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  height: 100vh;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--primary-color);
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
@media (min-width: 767px) {
  .container {
    width: 60%;
    background-color: rgb(255, 255, 255) !important;
    background: url("./assets/fondo_recetame.png");
  }
  html {
    background: none;
    background-color: rgb(255, 255, 255);
  }
  .app-container {
    background: url("./assets/fondo_recetame.png");
    width: 60%;
    background-color: rgb(255, 255, 255) !important;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
  }
  .content {
    width: 100%;
  }
}
@media (min-width: 1279px) {
  .container,
  .app-container {
    width: 40%;
  }

  .content {
    width: 100%;
  }
}
:root {
    --primary-color: #132853;
    --primary-color-2c: #1328532c;
    --secondary-color: #13328A;
    --error-color: #FF4141;
    --background-color: #ecf0f1;
    --menu-color: #D7EEF5;
    /* #1035D3 */
    /* #88CCE2 */
    /* #68CBEB29 */
    /* Agrega más colores según sea necesario */
}
</style>
